#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: initial;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(45, 52, 61, 0.9);
}

#modal-content {
  height: 95vh;
  position: absolute;
  padding: 35px;
  bottom: 0;
  width: 76%;
  background-color: #22272e;
  white-space: nowrap;
  border-radius: 10px;
  overflow-x: hidden;
}

@media screen and (max-width: 1200px) {
  #modal-content {
    width: 100vw;
  }
}

