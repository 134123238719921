#about-me {
  padding-top: 110px;
  margin: 0 200px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
}

#contact-links {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#portrait {
  padding: 20px;
  display: flex;
  justify-content: center;
}

#portrait img {
  border-radius: 25px;
  max-height: 240px;
}

#portrait-links {
  margin-top: 40px;
}

#portrait-links hr {
  margin: 0;
}

#contact-links a {
  padding: 10px 0;
  margin: 0 5px;
}

.visible {
  height: 3em;
  width: 10em;
}

.fab, .fas, .far {
  font-size: 2vw;
  margin: 0 15px;
  color: #97a2ad;
}

.fab:hover, .fas:hover, .far:hover {
  transition-duration: 0.3s;
  color: #cdd9e5;
}

#introduction {
  margin: 23px 0 0 23px;
  font-size: 19px;
}

#bio {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 100vw;
  max-height: 355px;
}

#bio p {
  margin-top: 6px;
  margin-bottom: 0;
  overflow: auto;
}

@media screen and (max-width: 1020px) and (min-width: 701px) {
  #about-me {
    display: flex;
    flex-direction: column;
  }

  .fab, .fas, .far {
    font-size: 3vw;
  }
}

@media screen and (max-width: 793px) and (min-width: 504px) {
  #about-me {
    display: flex;
    flex-direction: column;
    margin: 0 90px;
  }

  #portrait {
    align-self: flex-start;
  }

  .fab, .fas, .far {
    font-size: 3.5vw;
  }

  #bio {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 450px;
    font-size: 16px;
  }
}

@media screen and (max-width: 503px) {
  #about-me {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }

  #portrait {
    align-self: flex-start;
  }

  .fab, .fas, .far {
    font-size: 5vw;
  }

  #bio {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 450px;
    font-size: 16px;
  }
}

br {
  margin-bottom: 20px;
}