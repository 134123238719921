#portfolio {
  padding-top: 120px;
}

#portfolio-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  grid-template-rows: auto;
  padding: 0 14vw;
}

#portfolio h3 {
  margin-bottom: 30px;
  font-size: 25px;
}

.view-modal {
  display: flex;
  align-items: center;
  margin: 0 4vh;
}

.overview {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}

.overview-modal {
  display: flex;
}

.overview {
  margin-bottom: 30px;
}

.overview img {
  border-radius: 15px;
  display: flex;
  justify-self: flex-end;
}

.overview-modal img {
  border-radius: 15px;
  margin-right: 20px;
}

.title-snippet h4, .overview-title-snippet h2 {
  color: #cdd9e5;
  font-weight: bold;
}

.title-snippet h4 {
  margin: 8px 0 0 20px;
  font-size: 17px;
}

div h4, .overview-title-snippet p, .title-snippet p, div h2 {
  margin: auto;
}

.overview-title-snippet p, .title-snippet p {
  color: #768390;
  word-break: normal;
  white-space: normal;
}

.title-snippet p {
  width: 235px;
  margin: 3px 0 0 20px;
  word-break: normal;
  white-space: normal;
}

.view-btn {
  font-size: 14px;
  padding: 3px 13px;
}
