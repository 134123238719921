#contact-me {
  padding-top: 120px;
}

#contact-me h3 {
  margin-bottom: 30px;
  font-size: 25px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form label {
  margin-bottom: 5px;
  color: #cdd9e5;
}

.contact-form input {
  width: 40%;
  border-radius: 7px;
  padding: 5px 10px;
  margin-bottom: 15px;
  background-color: rgb(236, 235, 235);
  border-color: transparent;
}

.contact-form input:hover, .contact-form textarea:hover {
  background-color: white;
}

.contact-form input:focus, .contact-form textarea:focus {
  outline-color: transparent;
  outline: transparent;
}

.contact-form textarea {
  /* max-width: 40%;
  min-width: 40%; */
  width: 40%;
  min-height: 160px;
  max-height: 220px;
  border-radius: 7px;
  padding: 5px 10px;
  background-color: rgb(236, 235, 235);
  border-color: transparent;
}

#send-btn {
  margin-top: 20px;
  padding: 7px 18px;
}

.swal2-popup {
  border-radius: 8px !important;
  background-color: rgb(235, 235, 235);
}

.swal2-styled.swal2-confirm {
  background-color: #3c4249 !important;
  border-radius: 20px !important;
}

.swal2-styled.swal2-cancel {
  background-color: #bebebe !important;
}

.swal2-actions button {
  font-weight: 700 !important;
  color: #adbac7;
}

.swal2-actions button:focus {
  box-shadow: none !important;
}

@media screen and (min-width: 1200px) {
  #contact-me {
    margin: 0 10vw;
  }

  .contact-form input, .contact-form textarea {
    max-width: 60%;
  }
}

@media screen and (min-width: 900px) {
  #contact-me {
    margin: 0 15vw;
  }

  .contact-form input, .contact-form textarea {
    width: 60vw;
  }
}

@media screen and (max-width: 899px) {
  .contact-form input, .contact-form textarea {
    width: 75vw;
  }
}

