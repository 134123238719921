nav {
  width: 100%;
  background-color: #2d343b;
  color: #cdd9e5;
  font-weight: bold;
  font-size: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 0;
  position: fixed;
}

nav div {
  text-align: center;
  margin: 0px 25px;
  padding: 15px 0;
  width: 100px;
}

div a {
  /* font-family: 'Montserrat', sans-serif; */
  padding: 30px 0;
}

nav div:hover {
  color: #a5b2be;
  transition-duration: 0.1s;
  border-bottom: 2px solid #a5b2be;
}

@media screen and (max-width: 899px) {
  nav {
    font-size: 13px;
  }

  nav div {
    margin: 0;
    width: 88px;
  }
}