#skills {
  padding-top: 120px;
}

#skills h3 {
  margin-bottom: 30px;
  font-size: 25px;
}

#skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 3vw;
}

#skills-container i {
  font-size: 65px;
  margin: 10px;
}

#skills-container p:hover {
  border: 1px solid rgba(180, 193, 206, 0.699);
}

@media screen and (min-width: 1200px) {
  #skills-container {
    margin: 0 10vw;
  }
}

@media screen and (min-width: 900px) {
  #skills-container {
    margin: 0 15vw;
  }
}