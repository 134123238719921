html {
  scroll-behavior: smooth;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #22272e;
  color: #adbac7;
}

hr {
  color: #adbac7;
  opacity: 0.08;
  margin-top: 20px;
}

button {
  background-color: #3c4249;
  border: 1px solid #444c56;
  color: #adbac7;
  font-weight: bold;
  border-radius: 20px;
}

button:hover {
  transition-duration: 0.3s;
  background-color: #545d68;
  border: 1px solid rgba(180, 193, 206, 0.699);
}

h3 {
  font-size: 23px;
  display: flex;
  justify-content: center;
  color: #cdd9e5;
  font-weight: bold;
}